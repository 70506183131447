<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >
                <template v-if="true">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <template v-if="$validatePermission({ permissions: ['ChancelleryIncomingDocumentsList', 'ChancelleryOutcomingDocumentsList', 'ChancelleryInternalDocumentsList'], strategy: 'HAS_ANY' })">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        shaped
                    >
                        <v-list-group 
                            v-model="group1.active"
                            :accordion="true"
                        >
                            
                            <template v-slot:prependIcon>
                                <v-icon>far fa-books</v-icon>
                            </template>
                            <template v-slot:activator>
                                <v-list-item-title>{{ $t("Регистрация") }}</v-list-item-title>
                            </template>
                            <template v-slot:appendIcon>
                                <v-icon>fas fa-chevron-down</v-icon>
                            </template>

                            <template v-for="item in chancelleryItems">
                                <v-list-item
                                    link
                                    v-if="$validatePermission(item.access)"
                                    :key="item.route"
                                    :to="{ name: item.route }"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                                </v-list-item>
                            </template>

                        </v-list-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <template v-for="item in correspondenceItems">
                            <v-list-item
                                link
                                v-if="$validatePermission(item.access)"
                                :key="item.route"
                                :to="{ name: item.route }"
                            >
                                
                                <v-list-item-icon>
                                    <v-badge
                                        :value="item.counter > 0"
                                        color="red"
                                        overlap
                                        bottom
                                        dot
                                        >
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-badge>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                                <!--<div v-if="item.counter > 0" class="dm-counter">{{ item.counter }}</div>-->
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>

               
                <template>
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        shaped
                    >
                        <v-list-item-group>
                            <v-list-item
                                link
                                :to="{ name: 'CorrespondenceInWork' }"
                            >
                                <v-list-item-icon>
                                <v-icon>far fa-cogs</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("В_работе") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <template>
                    <v-list
                        nav
                        dense
                        shaped
                    >
                        <v-list-item-group>
                            <v-list-item
                                link
                                :to="{ name: 'CorrespondenceHandled' }"
                            >
                                <v-list-item-icon>
                                <v-icon>far fa-cogs</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Обработанные") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                
            </v-navigation-drawer>
        </div>

        <div class="content-box">
            <div v-if="$route.name == 'Correspondence'">
                Модуль "Корреспонденция"
            </div>
            <router-view v-else :key="$router.fullPath" />
            
        </div>

        <CreateCorrespondenceDocumentModal ref="CreateDlg"/>
    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import CreateCorrespondenceDocumentModal from '@/components/documents/dialogs/CreateCorrespondenceDocumentDlg.vue';
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash'

export default {
    name: "CorrespondenceMain",
    components: {
        CreateCorrespondenceDocumentModal
    },
    asyncComputed: {

    },
    computed: {
        ...mapGetters(
        {
            drawerLeftMenuMini: 'isDrawerLeftMenuMinimized'
        }),
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,

        group1: {
            active: false
        },

        counterTimer: null,
        counters: {
            incoming: -1,
            outgoing: -1,
            inner: -1,            
            dirord: -1,
            incomingActItems: -1,
            ord: -1,            
            protocol: -1,
            protocolact: -1
        },
        correspondenceItems: [
            {
                type: "project",
                name: "Проекты",
                route: "CorrespondenceProjects",
                icon: "far fa-folder-open",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "incoming",
                name: "Входящие",
                route: "CorrespondenceIncomings",
                icon: "far fa-inbox-in",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "outgoing",
                name: "Исходящие",
                route: "CorrespondenceOutgoings",
                icon: "far fa-inbox-out",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "inner",
                name: "Внутренние",
                route: "CorrespondenceInners",
                icon: "far fa-inbox",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "dirord",
                name: "ДИРОРД",
                route: "CorrespondenceDirords",
                icon: "far fa-circle",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "incomingActiItem",
                name: "Пункты_ДИРОРД",
                route: "CorrespondenceIncomingActItems",
                icon: "far fa-circle",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "ord",
                name: "ОРД",
                route: "CorrespondenceOrds",
                icon: "far fa-circle",
                access: { permissions: [] },
                counter: -1
            },
            {
                type: "innerActItem",
                name: "Пункты_ОРД",
                route: "CorrespondenceInnerActItems",
                icon: "far fa-circle",
                access: { permissions: [] },
                counter: -1
            }
        ],
        chancelleryItems: [
            {
                name: "Обработка_входящих",
                route: "CorrespondenceChancelleryIncomings",
                icon: "far fa-inbox-in",
                access: { permissions: ["ChancelleryIncomingDocumentsList"] }
            },
            {
                name: "Регистрация_исходящих",
                route: "CorrespondenceChancelleryOutgoings",
                icon: "far fa-inbox-out",
                access: { permissions: ["ChancelleryOutcomingDocumentsList"] }
            },
            {
                name: "Регистрация_внутренних",
                route: "CorrespondenceChancelleryInners",
                icon: "far fa-envelope",
                access: { permissions: ["ChancelleryInternalDocumentsList"] }
            },
            {
                name: "Отправка_резолюций",
                route: "CorrespondenceChancelleryResolutions",
                icon: "far fa-envelope",
                access: { permissions: ["ChancelleryOutcomingDocumentsList", "CanSignChancellery"] }
            }
        ],
    }),
    methods: {
        ...mapActions('global/actionsource', ['loadDataSource']),
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        async onCreateClicked() {
            try
            {
                let { params } = await this.$refs.CreateDlg.open();
                await this.loadDataSource({ createType: params.createType, navigate: true, common: params.isCommon ?? false });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        updateCounters: _.debounce(async function () { 
            clearInterval(this.counterTimer);
            var counterResponse = await httpAPI({ url: `/api/correspondence/counter`, method: 'GET', skipErrorHandler: true });

            //console.log('correspondense counters', counterResponse);
            
            let counterTypes = [
                "incoming",
                "dirord",
                "incomingActiItem",
                "outgoing",
                "inner",
                "ord"                   
            ];

            counterTypes.forEach((name, index) => {
                let idx = this.correspondenceItems.findIndex(x => x.type == name);
                if (idx != -1) {
                    this.correspondenceItems[idx].counter = counterResponse?.data?.Payload?.Data?.Object?.[index];
                }
            });

            // this.counters.incoming = counterResponse?.data?.Payload?.[0];
            // this.counters.dirord = counterResponse?.data?.Payload?.[1];
            // this.counters.section = counterResponse?.data?.Payload?.[2];
            // this.counters.outgoing = counterResponse?.data?.Payload?.[3];
            // this.counters.inner = counterResponse?.data?.Payload?.[4];
            // this.counters.ord = counterResponse?.data?.Payload?.[5];
            // this.counters.protocol = counterResponse?.data?.Payload?.[6];
            // this.counters.protocolact = counterResponse?.data?.Payload?.[7];
            this.counterTimer = setInterval(async () => {
                await this.updateCounters();
            }, 60000);
        }, 250),
    },
    mounted() {
        this.updateCounters();

        this.$eventBus.$on('update_correspondents_counters', async () => {
            this.updateCounters();
        });
    },
    beforeDestroy() {
        clearInterval(this.counterTimer);
        this.$eventBus.$off('update_correspondents_counters');
    }
}
</script>